import React from "react";
import { Link, Grid } from "@mui/material";

const Menu = ({ isMobile, isTinyWindow }) => {
  return (
    <Grid
      container
      direction="row"
      style={{
        padding: "0 5px 0 5px",
        marginBottom: "30px",
        fontSize: isMobile ? "20px" : "30px",
        color: "white",
        width: isTinyWindow() ? "50%" : "100%",
      }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Link
        href="https://violet-crown-74e.notion.site/Found-On-Mars-35473b7a1c104651bd914b678a988ae9"
        color="inherit"
      >
        STORY
      </Link>
      <Link href="https://w3bbie.xyz/" color="inherit">
        TEAM
      </Link>
      <Link
        href="https://violet-crown-74e.notion.site/Found-On-Mars-FAQ-df1c7c76129b4b1491555b85874040df"
        color="inherit"
      >
        FAQs
      </Link>
    </Grid>
  );
};

export default Menu;

import React from "react";
import VID from "./art.mp4";
import { Grid } from "@mui/material";
import wrapperGIF from "./wrapper.gif";

const Media = ({ imgWidth }) => {
  function isMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      {
        /*isMobile()*/ true ? (
          <img
            src={wrapperGIF}
            style={{
              width: imgWidth ? imgWidth : "100%",
              alignSelf: "center",
              height: "auto",
            }}
          />
        ) : (
          <video
            src={VID}
            style={{ width: "100%", alignSelf: "center", height: "auto" }}
            loop
            controls
            autoPlay
            muted
          />
        )
      }
    </Grid>
  );
};

export default Media;

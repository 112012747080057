import { useState } from "react";
import "./App.css";
import MainMint from "./MainMint";
import Media from "./Media";
import PassTypes from "./PassTypes.js";
import FAQs from "./FAQs.js";
import { Grid, Alert, Collapse, Link } from "@mui/material";
document.body.style.overflowX = "hidden";

function App() {
  const [accounts, setAccounts] = useState([]);
  const [hashLink, setHashLink] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);

  function isMobile() {
    return window.innerWidth < 1000 ? true : false;
  }

  function isTinyWindow() {
    return window.innerWidth < 700 ? true : false;
  }

  return (
    <Grid
      sx={{
        height: "100vh",
        width: "100vw",
        paddingTop: "30px",
        margin: 0,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Collapse in={showAlert}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "10px" }}
        >
          <Grid sx={{ width: "300px" }}>
            <Alert severity="success">
              Transaction sent.
              <Link
                href={hashLink}
                target="_blank"
                rel="noopener"
                onClick={(e) => {
                  setShowAlert(false);
                }}
              >
                View on etherscan.
              </Link>
            </Alert>
          </Grid>
        </Grid>
      </Collapse>

      <Collapse in={showAlertError}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "10px" }}
        >
          <Grid sx={{ width: "300px" }}>
            <Alert
              severity="error"
              onClose={() => {
                setShowAlertError(false);
              }}
            >
              Transaction failed.
            </Alert>
          </Grid>
        </Grid>
      </Collapse>

      <Grid
        container
        spacing={0}
        sx={{
          height: "100%",
          margin: 0,
          padding: 0,
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs="auto"
          sx={{
            padding: 0,
          }}
        >
          <MainMint
            accounts={accounts}
            setAccounts={setAccounts}
            isMobile={isMobile}
            isTinyWindow={isTinyWindow}
            setHashLink={setHashLink}
            setShowAlert={setShowAlert}
            setShowAlertError={setShowAlertError}
          />
        </Grid>
        {isMobile() ? null : (
          <Grid
            container
            xs={4}
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              padding: "20px",
              color: "white",
            }}
          >
            <Media imgWidth={"70%"} />
          </Grid>
        )}

        {/*
        <PassTypes />
        <FAQs />
        */}
      </Grid>
    </Grid>
  );
}

export default App;

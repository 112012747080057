import React from "react";
import { Button, Grid, Fab } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

const MintCounter = ({
  mintCount,
  setMintCount,
  isConnected,
  connectAccount,
  mint,
  isMobile,
}) => {
  return (
    <Grid
      container
      direction="row"
      style={{
        marginTop: isMobile() ? "-120px" : "auto",
        marginBottom: "20px",
        width: "200px",
      }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Remove
        style={{ color: "white" }}
        onClick={(e) => {
          if (mintCount > 1) setMintCount(mintCount - 1);
        }}
      />

      <Button
        color="secondary"
        onClick={(e) => {
          isConnected ? mint() : connectAccount();
        }}
        variant="contained"
        style={{
          fontSize: "20px",
          backgroundColor: "#DA0503",
          width: isConnected ? "100px" : "120px",
          whiteSpace: "normal",
        }}
      >
        {isConnected ? `Mint   ${mintCount}     ` : `Connect Wallet`}
      </Button>

      {/*
      <Fab
        color="secondary"
        aria-label="mintCount"
        style={{ backgroundColor: "#DA0503" }}
      >
        {mintCount}
      </Fab>
      */}
      <Add
        style={{ color: "white" }}
        onClick={(e) => {
          if (mintCount < 3) setMintCount(mintCount + 1);
        }}
      />
    </Grid>
  );
};

export default MintCounter;
